import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { SNACKBAR_CONFIG } from '../tokens/snackbar-config.token';
import { SnackbarReference } from '../types/snaakbar-reference.type';
import { SnackbarConfig } from '../types/snackbar-config.type';
import { SnackbarGlobalConfig } from '../types/snackbar-global-config.type';

@Injectable()
export class SnackbarService {
  constructor(
    @Inject(SNACKBAR_CONFIG)
    private readonly _globalConfig: SnackbarGlobalConfig,
    private readonly _snackbar: MatSnackBar
  ) {}

  public open({
    type,
    title,
    body,
    action,
    duration,
    icon,
    isLoading
  }: SnackbarConfig): SnackbarReference<SnackbarComponent> {
    const [horizontalPosition, verticalPosition] =
      this._globalConfig.positions[type || 'progress'];

    return this._snackbar.openFromComponent(SnackbarComponent, {
      panelClass: ['vlt-snackbar', type],
      duration: duration != null ? duration : 3000,
      horizontalPosition,
      verticalPosition,
      data: { title, body, action, icon, isLoading }
    });
  }

  public loading(title: string): SnackbarReference<SnackbarComponent> {
    return this.open({ title, isLoading: true, duration: 0 });
  }

  public dismiss(): void {
    return this._snackbar.dismiss();
  }
}
