import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@uikit/button/button.module';
import { IconModule } from '@uikit/icon/icon.module';
import { AuthService } from '../auth/services/auth.service';
import { I18nModule } from '../i18n/i18n.module';
import { OnboardingCardComponent } from './components/onboarding-card/onboarding-card.component';
import { OnboardingHotspotComponent } from './components/onboarding-hotspot/onboarding-hotspot.component';
import { OnboardingStepComponent } from './containers/onboarding-step/onboarding-step.component';
import { progressRepositoryResolver } from './di/progress-repository-resolver';
import { OnboardingInitializationModule } from './onboarding-initialization.module';
import { OnboardingProgressRepositoryService } from './services/onboarding-progress-repository.service';

@NgModule({
  declarations: [
    OnboardingCardComponent,
    OnboardingHotspotComponent,
    OnboardingStepComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    I18nModule,
    IconModule,
    OnboardingInitializationModule,
    OverlayModule
  ],
  exports: [OnboardingStepComponent],
  providers: [
    {
      provide: OnboardingProgressRepositoryService,
      useFactory: progressRepositoryResolver,
      deps: [AuthService, HttpClient]
    }
  ]
})
export class OnboardingModule {}
