import { Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICONS } from '../tokens/icons.token';

@Injectable()
export class IconService {
  constructor(
    @Inject(ICONS) private readonly _icons: string[],
    private readonly _iconRegistry: MatIconRegistry,
    private readonly _domSanitizer: DomSanitizer
  ) {}

  public registerAll(): void {
    this._icons.forEach(icon => {
      this._iconRegistry.addSvgIcon(
        icon,
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          `/assets/icons/${icon}.svg`
        )
      );
    });
  }
}
