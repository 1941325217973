import { Selector } from '@ngxs/store';
import { AppStateService } from './app.state.service';
import { AppState } from './app.state.type';

export class AppSelectors {
  @Selector([AppStateService])
  public static loadingSnackbar(state: AppState): AppState['loadingSnackbar'] {
    return state.loadingSnackbar;
  }

  @Selector([AppStateService])
  public static documents(state: AppState): AppState['documents'] {
    return state.documents;
  }
}
