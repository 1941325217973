import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { DocumentsRepositoryService } from '../core/services/documents.repository.service';
import { Documents } from '../core/types/documents.type';
import {
  GetDocuments,
  ToggleLoading as ToggleLoadingSnackbar
} from './app.actions';
import { AppState } from './app.state.type';

const defaultState: AppState = {
  loadingSnackbar: { show: false },
  documents: undefined
};

@State<AppState>({ name: 'app', defaults: defaultState })
@Injectable()
export class AppStateService {
  constructor(
    private readonly _documentsRepository: DocumentsRepositoryService
  ) {}

  @Action(ToggleLoadingSnackbar)
  public toggleLoadingSnackbar(
    context: StateContext<AppState>,
    { payload }: ToggleLoadingSnackbar
  ): void {
    context.patchState({ loadingSnackbar: payload });
  }

  @Action(GetDocuments)
  public getDocuments(
    context: StateContext<AppState>,
    { language }: GetDocuments
  ): Observable<Documents> {
    return this._documentsRepository
      .get(language)
      .pipe(tap(documents => context.patchState({ documents })));
  }
}
