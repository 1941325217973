// FIXME: If you work in this file, take 10 more minutes
// to remove these comments and fix linting issues
/* eslint-disable */
import { Inject, Injectable, NgZone } from '@angular/core';
import { EventManager, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';

@Injectable()
export class NativeEventManager extends EventManager {
  constructor(
    @Inject(EVENT_MANAGER_PLUGINS) plugins: any[],
    private zone: NgZone
  ) {
    super(plugins, zone);
  }

  public override addEventListener(
    element: HTMLElement,
    eventName: string,
    handler: Function
  ): Function {
    if (eventName.endsWith('outside-zone')) {
      eventName = eventName.split('.')[0];
      return this.zone.runOutsideAngular(() =>
        super.addEventListener(element, eventName, handler)
      );
    }

    return super.addEventListener(element, eventName, handler);
  }
}
