import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '@core/types/account.type';
import { Role } from '@core/types/role.type';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap
} from 'rxjs';
import { State } from '../types/oidc-state.type';
import { RoleCheckStrategy } from '../types/role-check-strategy.type';

@Injectable()
export class AuthService {
  public readonly isAuthenticated$ = this._oauthService.events.pipe(
    startWith(this.isAuthenticated),
    map(() => this.isAuthenticated),
    distinctUntilChanged()
  );

  public readonly user$ = this._loadUser();

  public get isAuthenticated(): boolean {
    return this._oauthService.hasValidAccessToken();
  }

  public get state(): State {
    const { state } = this._oauthService;
    return state ? JSON.parse(decodeURIComponent(state)) : {};
  }

  private get _roles(): Role[] {
    return (this._oauthService.getIdentityClaims() as Account)?.roles ?? [];
  }

  constructor(
    private readonly _oauthService: OAuthService,
    private readonly _router: Router
  ) {
    this._listenOtherTabs();
  }

  public async init(redirectTo?: string): Promise<void> {
    await this._oauthService.loadDiscoveryDocumentAndTryLogin();
    this._oauthService.setupAutomaticSilentRefresh();
    await firstValueFrom(this.user$);

    if (this.state.redirectTo) {
      void this._router.navigateByUrl(this.state.redirectTo);
    }

    if (redirectTo) void this._router.navigateByUrl(redirectTo);
  }

  public login(state?: State): void {
    return this._oauthService.initCodeFlow(
      state ? JSON.stringify(state) : undefined
    );
  }

  public hasRole(
    roleOrRoles: Role | Role[],
    check: RoleCheckStrategy = 'all'
  ): boolean {
    if (!Array.isArray(roleOrRoles)) return this._roles.includes(roleOrRoles);

    const checker = (role: Role): boolean => this._roles.includes(role);

    if (check === 'all') return roleOrRoles.every(checker);
    return roleOrRoles.some(checker);
  }

  public async logout(): Promise<void> {
    this._oauthService.logOut();
    await this._router.navigateByUrl(this._router.url);
    location.reload();
  }

  private _listenOtherTabs(): void {
    window.addEventListener('storage', event => {
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      if (!this.isAuthenticated) void this.logout();
      else void this.init(this._router.url);
    });
  }

  private _loadUser(): Observable<Account> {
    return this.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) return of(null);
        return this._oauthService.loadUserProfile() as unknown as Observable<{
          info: Account;
        }>;
      }),
      map(profile => profile?.info),
      shareReplay()
    );
  }
}
