import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from '../icon/icon.module';
import { SortHeaderComponent } from './components/sort-header/sort-header.component';
import { TableExpandableRowComponent } from './components/table-expandable-row/table-expandable-row.component';
import { TableExpandableToggleComponent } from './components/table-expandable-toggle/table-expandable-toggle.component';
import { TableExpandableComponent } from './components/table-expandable/table-expandable.component';
import { TableComponent } from './components/table/table.component';
import { SortDirective } from './directives/sort.directive';

@NgModule({
  declarations: [
    SortDirective,
    SortHeaderComponent,
    TableComponent,
    TableExpandableComponent,
    TableExpandableRowComponent,
    TableExpandableToggleComponent
  ],
  imports: [CommonModule, IconModule, MatTooltipModule],
  exports: [
    SortDirective,
    SortHeaderComponent,
    TableComponent,
    TableExpandableComponent,
    TableExpandableRowComponent,
    TableExpandableToggleComponent
  ]
})
export class TableModule {}
