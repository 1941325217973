import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ACCOUNT_PATHS } from './features/account/account-routing.paths';
import { QUOTES_PATHS } from './features/quotes/quotes.paths';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/marketing/marketing.module').then(
        m => m.MarketingModule
      )
  },

  {
    path: QUOTES_PATHS.list,
    loadChildren: () =>
      import('./features/quotes/quotes.module').then(m => m.QuotesModule)
  },
  {
    path: ACCOUNT_PATHS.details,
    loadChildren: () =>
      import('./features/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'kitchensink',
    loadChildren: () =>
      import('./styles/kitchensink/kitchensink.module').then(
        m => m.KitchensinkModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
