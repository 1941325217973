import { InjectionToken } from '@angular/core';
import { SnackbarGlobalConfig } from '../types/snackbar-global-config.type';

export const SNACKBAR_CONFIG = new InjectionToken<SnackbarGlobalConfig>(
  'Snackbar positions for different types',
  {
    factory: () => ({
      positions: { progress: ['start', 'bottom'], hint: ['center', 'top'] }
    })
  }
);
