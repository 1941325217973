import { APP_INITIALIZER, NgModule } from '@angular/core';
import { I18nService } from './services/i18n.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (i18n: I18nService) => () => {
        i18n.defaultLanguage = 'en';
        i18n.language = i18n.language;
      },
      deps: [I18nService],
      multi: true
    }
  ]
})
export class I18nInitializationModule {}
