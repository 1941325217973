import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { IconModule } from '@uikit/icon/icon.module';
import { IncludesModule } from '../../pipes/includes/includes.module';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
  declarations: [ContextMenuComponent, MenuComponent],
  imports: [CommonModule, IconModule, IncludesModule, MatMenuModule],
  exports: [ContextMenuComponent]
})
export class ContextMenuModule {}
