import { InjectionToken } from '@angular/core';
import { SpinnerConfig } from '../types/spinner-config.type';

export const SPINNER_CONFIG = new InjectionToken<SpinnerConfig>(
  'Spinner config',
  {
    factory: () => ({
      sizes: { small: 24, medium: 40, large: 60 },
      strokeFor: { small: 2, medium: 3, large: 3 }
    })
  }
);
