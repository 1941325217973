import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  tap
} from 'rxjs';
import { Step } from '../types/step.type';
import { OnboardingProgressRepositoryService } from './onboarding-progress-repository.service';
import { OnboardingStepsService } from './onboarding-steps.service';

@Injectable({ providedIn: 'root' })
export class OnboardingService {
  public readonly group$ = new BehaviorSubject<string>(null);
  public readonly totalSteps$ = this._getTotalSteps();
  public readonly activeStep$ = new BehaviorSubject<Step>(null);
  public readonly completedSteps$ = new BehaviorSubject<Step[]>(null);

  private set _progress(progress: Step[]) {
    this.completedSteps$.next(progress || []);
    this.activeStep$.next(this._getFirstUncompleted(progress || []));
  }

  constructor(
    private readonly _stepsService: OnboardingStepsService,
    private readonly _progressService: OnboardingProgressRepositoryService
  ) {}

  public start(group: string): Observable<void> {
    this.group$.next(group);
    return this._restoreProgress();
  }

  public stop(): void {
    this.group$.next(null);
    this._progress = null;
  }

  public toggle(step: Step): void {
    this.activeStep$.next(this.activeStep$.value?.id !== step.id ? step : null);
  }

  public async next(relativeTo = this.activeStep$.value): Promise<void> {
    const initiatingStep = this.activeStep$.value;

    const stepsToComplete = [
      ...this._getPriorUncompleted(relativeTo),
      relativeTo
    ];

    this.activeStep$.next(
      this._stepsService
        .get(this.group$.value)
        .find(({ order }) => order > relativeTo.order)
    );

    this.completedSteps$.next([
      ...this.completedSteps$.value,
      ...stepsToComplete
    ]);

    try {
      for (const { id } of stepsToComplete) {
        await this._progressService.save(this.group$.value, id);
      }
    } catch (error) {
      this.activeStep$.next(initiatingStep);
      this.completedSteps$.next(
        this.completedSteps$.value.filter(
          ({ id }) => !stepsToComplete.map(step => step.id).includes(id)
        )
      );

      throw error;
    }
  }

  public async skip(): Promise<void> {
    return this.next(this._stepsService.getLast(this.group$.value));
  }

  public async resetProgress(group = this.group$.value): Promise<void> {
    await this._progressService.reset(group);
    this._progress = null;
  }

  public async syncProgress(): Promise<void> {
    const local = JSON.parse(localStorage.getItem('onboarding')) as Record<
      string,
      number[]
    >;

    if (!local) return;

    for (const [group, steps] of Object.entries(local)) {
      if (!steps?.length) {
        await this._progressService.reset(group);
        continue;
      }

      for (const step of steps) await this._progressService.save(group, step);
    }
  }

  private _restoreProgress(): Observable<void> {
    const progressReference = this._progressService.get(this.group$.value);

    return (
      progressReference instanceof Observable
        ? progressReference
        : of(progressReference)
    ).pipe(
      map(ids => {
        return ids.map(id => {
          return this._stepsService.getBy('id', this.group$.value, id);
        });
      }),
      tap(progress => (this._progress = progress)),
      map(() => null)
    );
  }

  private _getPriorUncompleted(step: Step): Step[] {
    const steps: Step[] = [];

    for (let id = step.id - 1; id >= 1; id--) {
      const priorStep = this._stepsService.getBy('id', this.group$.value, id);

      const isComplete = this.completedSteps$.value.some(
        completedStep => completedStep.id === priorStep?.id
      );

      const currentStep = this._stepsService.getBy(
        'id',
        this.group$.value,
        step.id
      );

      if (priorStep?.order < currentStep.order && !isComplete) {
        steps.push(priorStep);
      }
    }

    return steps.reverse();
  }

  private _getFirstUncompleted(completedSteps: Step[]): Step {
    return this._stepsService.get(this.group$.value)?.find(step => {
      return !completedSteps.map(innerStep => innerStep?.id).includes(step.id);
    });
  }

  private _getTotalSteps(): Observable<number> {
    return combineLatest([this.group$, this._stepsService.added$]).pipe(
      filter(([group]) => !!group),
      map(([group]) => this._stepsService.getTotalNumber(group))
    );
  }
}
