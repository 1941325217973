import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { I18nService } from './i18n.service';

@Injectable()
export class APILanguageInterceptor implements HttpInterceptor {

  constructor(private readonly _i18nService: I18nService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(
      request.clone({
        setHeaders: { 'Accept-Language': this._i18nService.language },
      })
    );
  }
}
