import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18nModule } from '@i18n/i18n.module';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { SafePipeModule } from '../pipes/safe-pipe/safe-pipe.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { SnackbarModule } from '../snackbar/snackbar.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TableModule } from '../table/table.module';
import { DropzoneComponent } from './components/dropzone/dropzone.component';
import { FileListContextMenuComponent } from './components/file-list-context-menu/file-list-context-menu.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { TableFileListComponent } from './components/table-file-list/table-file-list.component';
import { UploadComponent } from './containers/upload/upload.component';
import { CreateObjectURLPipe } from './pipes/create-object-url.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';

@NgModule({
  declarations: [
    CreateObjectURLPipe,
    DropzoneComponent,
    FileListComponent,
    FileListContextMenuComponent,
    FileSizePipe,
    TableFileListComponent,
    UploadComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    I18nModule,
    IconModule,
    MatMenuModule,
    MatTooltipModule,
    ProgressBarModule,
    SafePipeModule,
    SnackbarModule,
    SpinnerModule,
    TableModule
  ],

  exports: [
    CreateObjectURLPipe,
    DropzoneComponent,
    FileListComponent,
    FileSizePipe,
    TableFileListComponent,
    UploadComponent
  ]
})
export class UploadModule {}
