import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ContextMenuModule } from '@shared/features/context-menu/context-menu.module';
import { UploadModule } from '@uikit/upload/upload.module';
import { I18nModule } from '../i18n/i18n.module';
import { OnboardingModule } from '../onboarding/onboarding.module';
import { CanvasComponent } from './components/canvas/canvas.component';
import { GridComponent } from './components/grid/grid.component';
import { DrawingRoutingModule } from './drawing-routing.module';
import { CanvasStateService } from './state/canvas-state/canvas.state.service';

@NgModule({
  imports: [
    CommonModule,
    ContextMenuModule,
    DrawingRoutingModule,
    I18nModule,
    OnboardingModule,
    UploadModule,
    NgxsModule.forFeature([CanvasStateService])
  ],
  declarations: [CanvasComponent, GridComponent],
  exports: [CanvasComponent]
})
export class DrawingModule {}
