import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconComponent } from './components/icon/icon.component';
import { IconService } from './services/icon.service';

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, MatIconModule],
  providers: [
    IconService,
    {
      provide: APP_INITIALIZER,
      useFactory: (iconService: IconService) => () => iconService.registerAll(),
      deps: [IconService],
      multi: true
    }
  ],
  exports: [IconComponent]
})
export class IconModule {}
