import { Injectable } from '@angular/core';
import { OnboardingProgressRepository } from '../types/onboarding-progress-repository.interface';

@Injectable()
export class LocalOnboardingProgressRepositoryService
  implements OnboardingProgressRepository
{
  public getAll(): Record<string, number[]> {
    return JSON.parse(localStorage.getItem('onboarding'));
  }

  public get(group: string): number[] {
    return this.getAll()?.[group] || [];
  }

  public async save(group: string, step: number): Promise<void> {
    const groups = this.getAll() || {};
    groups[group] = [...(groups[group] || []), step];

    if (this.get(group).includes(step)) return;

    return localStorage.setItem('onboarding', JSON.stringify(groups));
  }

  public async reset(group: string): Promise<void> {
    const groups = this.getAll() || {};
    groups[group] = [];

    return localStorage.setItem('onboarding', JSON.stringify(groups));
  }
}
