import { LoadingSnackbar } from '../types/loading-snackbar.type';

export class ToggleLoading {
  public static readonly type = '[App] ToggleLoading';
  constructor(public readonly payload: LoadingSnackbar) {}
}

export class GetDocuments {
  public static readonly type = '[App] GetDocuments';
  constructor(public readonly language: string) {}
}
