import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';
import { ColorPalettes } from '@uikit/types/color-palettes.type';

@Component({
  selector: 'vlt-icon',
  host: { class: 'vlt-icon' },
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  @Input()
  public name: string;

  @HostBinding('class')
  @Input()
  public size: 'small' | 'medium' | 'large' | 'x-large' = 'medium';

  @Input()
  public color: ColorPalettes;
}
