import { Door } from '@core/types/door.type.';
import { Wall } from '@core/types/wall.type';
import { ProjectCanvas } from '@drawing/models/project-canvas.model';
import { PlanImage } from '../../models/plan-image';
import { SerializableCanvas } from '../../models/serializable-models/serializable-canvas.model';
import { PlanImageSettings } from '../../types/plan-image-settings.type';
import { Tool } from '../../types/tool.type';

export class SetCanvasReference {
  public static readonly type = '[Canvas] SetCanvasReference';
  constructor(public readonly canvas: ProjectCanvas) {}
}

export class SetPlanReference {
  public static readonly type = '[Canvas] SetPlanReference';
  constructor(public readonly plan: PlanImage) {}
}

export class SetPlan {
  public static readonly type = '[Canvas] SetPlan';
  constructor(public readonly plan?: Blob | File) {}
}

export class SetPlanSettings {
  public static readonly type = '[Canvas] SetPlanSettings';
  constructor(public readonly settings: PlanImageSettings) {}
}

export class SetDrawing {
  public static readonly type = '[Canvas] SetDrawing';
  constructor(public readonly drawing: SerializableCanvas) {}
}

export class PrepareForExport {
  public static readonly type = '[Canvas] PrepareForExport';
}

export class SetWalls {
  public static readonly type = '[Canvas] SetWalls';
  constructor(public readonly walls: Wall[]) {}
}

export class SetDoors {
  public static readonly type = '[Canvas] SetDoors';
  constructor(public readonly doors: Door[]) {}
}

export class SetTool {
  public static readonly type = '[Canvas] SetTool';
  constructor(public readonly tool: Tool) {}
}

export class Rotate {
  public static readonly type = '[Canvas] Rotate';
  constructor(public readonly angle: number) {}
}

export class TogglePlanEditMode {
  public static readonly type = '[Canvas] TogglePlanEditMode';
  constructor(public readonly enable: boolean) {}
}

export class ToggleBaseline {
  public static readonly type = '[Canvas] ToggleBaseline';
  constructor(public readonly show: boolean) {}
}

export class ToggleContinuousDrawing {
  public static readonly type = '[Canvas] ToggleContinuousDrawing';
  constructor(public readonly enable: boolean) {}
}

export class Destroy {
  public static readonly type = '[Canvas] Destroy';
}
