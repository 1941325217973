import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { SPINNER_CONFIG } from '../../tokens/default-spinner-config.token';
import { SpinnerConfig } from '../../types/spinner-config.type';

@Component({
  selector: 'vlt-spinner',
  host: { class: 'vlt-spinner' },
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent {
  @Input() public value: number;
  @Input() public size: 'small' | 'medium' | 'large' = 'small';

  @HostBinding('class') @Input() public color:
    | 'primary'
    | 'on-primary'
    | 'accent'
    | 'on-accent'
    | 'warn'
    | 'on-warn' = 'primary';

  constructor(@Inject(SPINNER_CONFIG) public config: SpinnerConfig) {}
}
