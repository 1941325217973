import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const AUTH_CONFIG: AuthConfig = {
  oidc: false,
  issuer: environment.auth.issuer,
  clientId: environment.auth.clientId,
  scope: 'openid voltage_web_api',
  responseType: 'code',
  useSilentRefresh: true,
  showDebugInformation: !environment.production,
  redirectUri: window.location.origin,
  silentRefreshRedirectUri: `${window.location.origin}/auth-silent-refresh.html`,
  userinfoEndpoint: `${environment.resourceApiUrl}/users/me`
};
