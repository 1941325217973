import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, debounceTime, firstValueFrom, tap } from 'rxjs';
import { AuthService } from './features/auth/services/auth.service';
import { I18nService } from './features/i18n/services/i18n.service';
import { OnboardingService } from './features/onboarding/services/onboarding.service';
import { ToggleOnboardingButton } from './layout/state/layout.actions';
import { AnalyticsService } from './shared/services/analytics.service';
import { GetDocuments } from './state/app.actions';
import { AppSelectors } from './state/app.selectors';
import { LoadingSnackbar } from './types/loading-snackbar.type';
import { SnackbarComponent } from './uikit/snackbar/components/snackbar/snackbar.component';
import { SnackbarService } from './uikit/snackbar/services/snackbar.service';
import { SnackbarReference } from './uikit/snackbar/types/snaakbar-reference.type';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public readonly activeOnboardingGroup$ = this._onboarding.group$.pipe(
    debounceTime(100),
    tap(group => this._store.dispatch(new ToggleOnboardingButton(!!group)))
  );

  @Select(AppSelectors.loadingSnackbar)
  private readonly _loading$: Observable<LoadingSnackbar>;

  constructor(
    private readonly _authService: AuthService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _snackbar: SnackbarService,
    private readonly _i18n: I18nService,
    private readonly _store: Store,
    private readonly _onboarding: OnboardingService
  ) {}

  public ngOnInit(): void {
    void this._configureAnalytics();
    this._toggleLoading().subscribe();
    this._loadDocuments();
  }

  private async _configureAnalytics(): Promise<void> {
    if (
      !this._authService.isAuthenticated ||
      !this._analyticsService.isLoaded
    ) {
      return;
    }

    const { id } = await firstValueFrom(this._authService.user$);
    this._analyticsService.identifyUser(id);
  }

  private _toggleLoading(): Observable<LoadingSnackbar> {
    let snackbar: SnackbarReference<SnackbarComponent>;

    return this._loading$.pipe(
      tap(({ show, title }) => {
        if (!show) {
          setTimeout(() => snackbar?.dismiss(), 1000);
          return;
        }

        snackbar = this._snackbar.loading(
          title || this._i18n.translate('COMMON.loading')
        );
      })
    );
  }

  private _loadDocuments(): Observable<void> {
    return this._store.dispatch(new GetDocuments(this._i18n.language));
  }
}
