import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackbarData } from '../../types/snackbar-config.type';

@Component({
  host: { class: 'content' },
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public readonly data: SnackbarData) {}
}
