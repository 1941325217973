import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OnboardingProgressRepository } from '../types/onboarding-progress-repository.interface';
import { LocalOnboardingProgressRepositoryService } from './local-onboarding-progress-repository.service';

@Injectable()
export class OnboardingProgressRepositoryService
  implements OnboardingProgressRepository
{
  private readonly _apiURL = `${environment.resourceApiUrl}/me/tutorial`;

  constructor(
    private readonly _http: HttpClient,
    private readonly _localProgressRepositoryService: LocalOnboardingProgressRepositoryService
  ) {}

  public async save(group: string, step: number): Promise<void> {
    await this._localProgressRepositoryService.save(group, step);

    return firstValueFrom(
      this._http.post<void>(`${this._apiURL}/${group}/step/${step}`, null)
    );
  }

  public get(group: string): Observable<number[]> {
    return this._http.get<number[]>(`${this._apiURL}/${group}`);
  }

  public async reset(group: string): Promise<void> {
    await this._localProgressRepositoryService.reset(group);

    return firstValueFrom(this._http.delete<void>(`${this._apiURL}/${group}`));
  }
}
