import { Injectable } from '@angular/core';

declare const hj: (feature: string, ...args: unknown[]) => void;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  public readonly isLoaded = window.hasOwnProperty('hj');

  public identifyUser(id: string, attributes?: Record<string, unknown>): void {
    return hj('identify', id, attributes || {});
  }
}
