import { HttpClient } from '@angular/common/http';
import { AuthService } from '@auth/services/auth.service';
import { LocalOnboardingProgressRepositoryService } from '../services/local-onboarding-progress-repository.service';
import { OnboardingProgressRepositoryService } from '../services/onboarding-progress-repository.service';
import { OnboardingProgressRepository } from '../types/onboarding-progress-repository.interface';

export function progressRepositoryResolver(
  authService: AuthService,
  http: HttpClient
): OnboardingProgressRepository {
  return authService.isAuthenticated
    ? new OnboardingProgressRepositoryService(
        http,
        new LocalOnboardingProgressRepositoryService()
      )
    : new LocalOnboardingProgressRepositoryService();
}
