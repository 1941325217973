import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Documents } from '../types/documents.type';

@Injectable()
export class DocumentsRepositoryService {
  constructor(private readonly _http: HttpClient) {}

  public get(language: string): Observable<Documents> {
    return this._http.get<Documents>(`/documents/${language}`);
  }
}
