import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { OnboardingService } from './services/onboarding.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService, onboarding: OnboardingService) => () => {
        return auth.isAuthenticated && onboarding.syncProgress();
      },
      deps: [AuthService, OnboardingService],
      multi: true
    }
  ]
})
export class OnboardingInitializationModule {}
