import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IconModule } from '../icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SnackbarService } from './services/snackbar.service';

@NgModule({
  declarations: [SnackbarComponent],
  imports: [CommonModule, IconModule, MatSnackBarModule, SpinnerModule],
  providers: [SnackbarService]
})
export class SnackbarModule {}
