import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class APIBaseURLInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.startsWith('/assets') ||
      request.url.startsWith('./assets') ||
      request.url.startsWith('http')
    ) {
      return next.handle(request);
    }

    return next.handle(
      request.clone({ url: `${environment.resourceApiUrl}${request.url}` })
    );
  }
}
