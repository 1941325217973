import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { PlanImage } from '../../models/plan-image';
import { ProjectCanvas } from '../../models/project-canvas.model';
import {
  Destroy,
  PrepareForExport,
  Rotate,
  SetCanvasReference,
  SetDoors,
  SetDrawing,
  SetPlan,
  SetPlanReference,
  SetPlanSettings,
  SetTool,
  SetWalls,
  ToggleBaseline,
  ToggleContinuousDrawing,
  TogglePlanEditMode
} from './canvas.actions';
import { CanvasState } from './canvas.state.type';

export const defaults: CanvasState = {
  walls: undefined,
  doors: undefined,
  tool: undefined,
  plan: undefined,
  planSettings: undefined,
  drawing: undefined,
  planEditMode: undefined,
  showBaseline: undefined,
  continuousDrawing: undefined,
  imageSnapshot: undefined
};

@State<CanvasState>({ name: 'canvas', defaults })
@Injectable()
export class CanvasStateService {
  private _canvas: ProjectCanvas;
  private _plan: PlanImage;

  @Action(SetCanvasReference)
  public setCanvasReference(
    context: StateContext<CanvasState>,
    { canvas }: SetCanvasReference
  ): void {
    this._canvas = canvas;
  }

  @Action(SetPlanReference)
  public setPlanReference(
    context: StateContext<CanvasState>,
    { plan }: SetPlanReference
  ): void {
    this._plan = plan;
  }

  @Action(SetPlan)
  public setPlan(context: StateContext<CanvasState>, { plan }: SetPlan): void {
    context.patchState({ plan });
  }

  @Action(SetPlanSettings)
  public setPlanSettings(
    context: StateContext<CanvasState>,
    { settings }: SetPlanSettings
  ): void {
    const planSettings = settings
      ? { ...context.getState().planSettings, ...(settings || {}) }
      : { opacity: 100, contrast: 100, saturation: 100, rotationAngle: 0 };

    context.patchState({ planSettings });
  }

  @Action(SetDrawing)
  public setDrawing(
    context: StateContext<CanvasState>,
    { drawing }: SetDrawing
  ): void {
    context.patchState({ drawing });
  }

  @Action(SetWalls)
  public setWalls(
    context: StateContext<CanvasState>,
    { walls }: SetWalls
  ): void {
    context.patchState({ walls });
  }

  @Action(SetDoors)
  public setDoors(
    context: StateContext<CanvasState>,
    { doors }: SetDoors
  ): void {
    context.patchState({ doors });
  }

  @Action(SetTool)
  public setTool(context: StateContext<CanvasState>, { tool }: SetTool): void {
    const currentTool = context.getState().tool;
    const newTool = { ...currentTool, ...tool };

    context.patchState({
      tool: currentTool?.brush !== newTool?.brush ? newTool : null
    });
  }

  @Action(Rotate)
  public rotate(context: StateContext<CanvasState>, { angle }: Rotate): void {
    const { planSettings } = context.getState();
    let newAngle = (planSettings?.rotationAngle || 0) + angle;

    if (newAngle >= 360 || newAngle <= -360) newAngle = 0;

    context.patchState({
      planSettings: { ...planSettings, rotationAngle: newAngle }
    });
  }

  @Action(TogglePlanEditMode)
  public togglePlanEditMode(
    context: StateContext<CanvasState>,
    { enable }: TogglePlanEditMode
  ): void {
    context.patchState({ planEditMode: enable });
  }

  @Action(ToggleBaseline)
  public toggleBaseline(
    context: StateContext<CanvasState>,
    { show: enable }: ToggleBaseline
  ): void {
    context.patchState({ showBaseline: enable });
  }

  @Action(ToggleContinuousDrawing)
  public toggleContinuousDrawing(
    context: StateContext<CanvasState>,
    { enable }: ToggleContinuousDrawing
  ): void {
    context.patchState({ continuousDrawing: enable });
  }

  @Action(PrepareForExport)
  public async prepareForExport(
    context: StateContext<CanvasState>
  ): Promise<void> {
    context.patchState({
      imageSnapshot: await this._canvas?.toImage(),
      drawing: this._canvas?.toSerializableModel(),
      planSettings: {
        ...context.getState().planSettings,
        ...this._plan?.settings
      }
    });
  }

  @Action(Destroy)
  public destroy(context: StateContext<CanvasState>): void {
    this._canvas?.dispose();
    context.setState(defaults);
  }
}
