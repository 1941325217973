import { ComponentType } from '@angular/cdk/portal';

export class SetHeaderLeftSideSlot {
  public static readonly type = '[Header] Set Left Side Slot Component';
  constructor(public readonly component: ComponentType<unknown>) {}
}

export class SetHeaderRightSideSlot {
  public static readonly type = '[Header] Set Right Side Slot Component';
  constructor(public readonly component: ComponentType<unknown>) {}
}

export class ToggleOnboardingButton {
  public static readonly type = '[Header] ToggleOnboardingButton';
  constructor(public readonly show: boolean) {}
}
