import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import {
  DEFAULT_LANGUAGE,
  MissingTranslationHandler as NgxTranslateMissingTranslationHandler,
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateFakeCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
  TranslatePipe,
  TranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  USE_STORE
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IconModule } from '@uikit/icon/icon.module';
import { LanguageSwitcherComponent } from './containers/language-switcher/language-switcher.component';
import { I18nInitializationModule } from './i18n-initialization.module';
import { MissingTranslationHandler } from './services/missing-translation-handler';

@NgModule({
  declarations: [LanguageSwitcherComponent],
  imports: [
    CommonModule,
    I18nInitializationModule,
    IconModule,
    MatMenuModule,
    TranslateModule
  ],
  exports: [LanguageSwitcherComponent, TranslatePipe]
})
export class I18nModule {
  public static forRoot(): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        TranslateService,
        TranslateStore,
        { provide: USE_STORE, useValue: false },
        { provide: USE_DEFAULT_LANG, useValue: undefined },
        { provide: USE_EXTEND, useValue: true },
        { provide: DEFAULT_LANGUAGE, useValue: undefined },
        { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        { provide: TranslateParser, useClass: TranslateDefaultParser },
        {
          provide: NgxTranslateMissingTranslationHandler,
          useClass: MissingTranslationHandler
        },
        {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
          deps: [HttpClient]
        }
      ]
    };
  }
}
