import {
  MissingTranslationHandler as NgxTranslateMissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';

export class MissingTranslationHandler
  implements NgxTranslateMissingTranslationHandler
{
  public handle({
    key,
    interpolateParams,
    translateService
  }: MissingTranslationHandlerParams): string {
    if (!interpolateParams?.hasOwnProperty('fallback')) return key;

    return translateService.instant(
      (interpolateParams as Record<string, unknown> & { fallback: string })
        .fallback,
      interpolateParams
    );
  }
}
