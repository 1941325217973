import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { AUTH_CONFIG } from './auth.config';
import { NgIfRoleDirective } from './directives/ng-if-role.directive';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.resourceApiUrl, '/'],
        sendAccessToken: true
      }
    })
  ],
  providers: [
    { provide: AuthConfig, useValue: AUTH_CONFIG },
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService) => () => auth.init(),
      deps: [AuthService],
      multi: true
    }
  ],
  declarations: [NgIfRoleDirective],
  exports: [NgIfRoleDirective]
})
export class AuthModule {}
