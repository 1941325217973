import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { LANGUAGES } from '../tokens/languages.token';
import { Language } from '../types/language.type';

@Injectable({ providedIn: 'root' })
export class I18nService {
  constructor(
    @Inject(LANGUAGES)
    public readonly languages: Language[],
    private readonly _translateService: TranslateService
  ) {}

  public get language(): Language['abbr'] {
    const userSelectedLanguage = localStorage.getItem(
      'language'
    ) as Language['abbr'];

    return userSelectedLanguage || this._getBrowserPreferredSupportedLanguage();
  }

  public set language(language: Language['abbr']) {
    localStorage.setItem('language', language);
    void firstValueFrom(this._translateService.use(language));
  }

  public get defaultLanguage(): Language['abbr'] {
    return this._translateService.getDefaultLang() as Language['abbr'];
  }

  public set defaultLanguage(language: Language['abbr']) {
    this._translateService.setDefaultLang(language);
  }

  public translate(
    key: string | string[],
    interpolationParams?: Record<string, unknown>
  ): string {
    return this._translateService.instant(key, interpolationParams);
  }

  private _getBrowserPreferredSupportedLanguage(): Language['abbr'] {
    let language: Language['abbr'];
    const exclude: string[] = [];

    while (!this.languages.map(({ abbr }) => abbr).includes(language)) {
      exclude.push(language);
      language = this._getBrowserPreferredLanguage(exclude.filter(Boolean));
    }

    return language || this.defaultLanguage;
  }

  private _getBrowserPreferredLanguage(not: string[] = []): Language['abbr'] {
    const languages = window.navigator.languages.filter(language => {
      return !not.includes(this._formatLocale(language));
    });

    return this._formatLocale(languages[0]);
  }

  private _formatLocale(locale: string): Language['abbr'] {
    return locale.split('-')[0] as Language['abbr'];
  }
}
