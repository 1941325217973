import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { ButtonComponent } from './components/button/button.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ToolButtonComponent } from './components/tool-button/tool-button.component';

@NgModule({
  declarations: [ButtonComponent, IconButtonComponent, ToolButtonComponent],
  imports: [CommonModule, IconModule],
  exports: [ButtonComponent, IconButtonComponent, ToolButtonComponent]
})
export class ButtonModule {}
