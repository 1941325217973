import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Step } from '../types/step.type';

@Injectable({ providedIn: 'root' })
export class OnboardingStepsService {
  public readonly added$ = new BehaviorSubject<Step>(null);
  private readonly _groups = new Map<string, Step[]>();

  public get(group: string): Step[] {
    return this._groups.get(group);
  }

  public getBy(key: keyof Step, group: string, reference: unknown): Step {
    return this.get(group)?.find(step => step[key] === reference);
  }

  public getLast(group: string): Step {
    return this.get(group)?.[this.getTotalNumber(group) - 1];
  }

  public add(step: Step): void {
    if (
      this.getBy('id', step.group, step.id) ||
      this.getBy('order', step.group, step.order)
    ) {
      return;
    }

    this._groups.set(
      step.group,
      [...(this.get(step.group) || []), step].sort((a, b) => a.order - b.order)
    );

    this.added$.next(step);
  }

  public getNumber(step: Step): number {
    return (
      this.get(step.group).findIndex(({ id }) => id === step?.id) + 1 || null
    );
  }

  public getTotalNumber(group: string): number {
    return this.get(group)?.length;
  }
}
